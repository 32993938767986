<template>
  <div>
    CP-Website
  </div>
</template>

<script>
export default {}
</script>

<style></style>
